.forminput {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .forminput__title {
    font-size: 1rem;
    font-weight: 600;
  }

  .forminput__input {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
  }
}
