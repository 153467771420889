@import '../../_mixin';
@import '../../_variables';

.hero {
  position: relative;
  padding: 2rem 0;

  .hero__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $secondary85;
    }
  }

  .hero__container {
    @include container;

    position: relative;
    z-index: 5;
    color: white;
    font-size: 1.25rem;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: start;
  }
}
