@import '../../_mixin';
@import '../../_variables';

.about {
  @include container;
  padding: 2rem 0;

  display: flex;
  flex-direction: column;
}
