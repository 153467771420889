.card__popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 40rem;
  max-height: 60vh;
  overflow-y: auto;
  width: 90%;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  z-index: 25000;
}

.card__background {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2000;
}
