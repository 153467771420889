@import '../../_mixin';
@import '../../_variables';

.popup__header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.popup__separator {
  margin: 1rem 0;
}

.popup__form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .form__title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
}

.popup__title {
  font-weight: 700;
  font-size: 1.25rem;
  text-decoration: underline;
}

.popup__body {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.popup__button {
  padding: 0.75rem 1.25rem;
  background-color: $primary;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  color: white;
  margin-top: 0.5rem;
}

.card__distance {
  font-size: 1.25rem;
  font-weight: 800;
}

.card__name {
  font-size: 1.5rem;
  font-weight: 400;
}

.card {
  border-radius: 0.5rem;
  background-color: $primary;
  color: white;
  display: flex;
  flex-direction: column;

  .card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .card__footer {
    padding: 0.75rem 1rem;
    margin-top: auto;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 0.5rem 0.5rem;
  }
}
