@import '../../_mixin';
@import '../../_variables';

.info {
  background-color: #f7f7f7;
  padding: 2rem 0;

  .info__container {
    @include container;
    display: grid;

    grid-template-columns: repeat(1, 1fr);

    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }

    .info__body {
      p {
        font-size: 1.25rem;
        text-indent: 2rem;
        line-height: 2rem;
      }
    }

    .info__image {
      height: 0;
      padding-bottom: 60%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
