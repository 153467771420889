@import '../../../_mixin';
@import '../../../_variables';

.header {
  padding: 1rem 0rem;

  .header__container {
    @include container;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header__body {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: center;

      .header__button {
        border: none;
        background-color: $primary;
        color: white;
        padding: 0.75rem 1.5rem;
        border-radius: 0.25rem;
        font-size: 1.25rem;
      }

      .header__link {
        font-weight: 500;
        font-size: 1.25rem;
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
