@import '../../_mixin';

.map {
  background-color: #f7f7f7f7;
}

.header__title {
  font-size: 2rem;
  font-weight: 800;
  color: $primary;
  padding: 0;
  margin: 0;
}

.header__text {
  font-size: 1.25rem;
  margin-top: 0.5rem;
  font-weight: 700;
}

.header__message {
  color: red;
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

.map__container {
  @include container;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .map__header {
    display: flex;
    flex-direction: column;

    .header__sectii {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include sm {
        grid-template-columns: repeat(4, 1fr);
      }
      gap: 1rem;
    }

    .header__search {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include sm {
        flex-direction: row;
      }
      gap: 0.5rem;
      padding-top: 1rem;

      .header__input {
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        outline: none;
        flex: 1;
        font-size: 1.5rem;
        font-weight: 700;
      }

      .header__button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid $primary;
        font-size: 1.5rem;
        cursor: pointer;
        background-color: $primary;
        color: white;
        transition: all 0.25s ease-out;

        &:hover {
          background-color: transparent;
          color: $primary;
        }
      }
    }
  }

  .map {
    width: 80%;
    height: 20rem;
    max-width: 60rem;
    margin: auto;
    border: none;

    .leaflet-container {
      width: 100%;
      height: 20rem;
    }
  }
}
