@import '../../../_variables';
@import '../../../_mixin';

.footer {
  background-color: $primary;

  .footer__container {
    @include container;

    padding: 1rem 0;
    color: white;
  }
}
