$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

$container-width: 1280px;

$primary: rgba(86, 64, 139, 255);
$secondary: rgba(62, 181, 208, 255);

$secondary85: rgba(62, 181, 208, 0.85);
