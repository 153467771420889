@import '../../../_variables';

.logo {
  height: 4rem;
}

.logo__big {
  height: 8rem;
}

.logo__small {
  height: 2rem;
}
