.rides__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;

  .rides__message {
    font-weight: 700;
    font-size: 1.25rem;
  }
}
