@import '../../_mixin';
@import '../../_variables';

.whyvote {
  background-color: white;
  .whyvote__container {
    @include container;
    padding: 2rem 0;
  }

  .whyvote__body {
    font-size: 1.25rem;
  }
}
