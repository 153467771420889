@import '../../_mixin';
@import '../../_variables';

.col {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.contact {
  padding: 2rem 0;
  background-color: white;

  .contact__container {
    @include container;
    display: flex;
    flex-direction: column;

    .contact__body {
      padding-top: 1rem;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      gap: 2rem;

      @include sm {
        grid-template-columns: repeat(2, 1fr);
      }

      .contact__item {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        background-color: #f7f7f7f7;
        padding: 1rem 1.5rem;
        border-radius: 2rem;

        a {
          font-size: 1.25em;
          text-decoration: none;
          color: black;
        }

        .item__image {
          color: $primary;
          font-size: 2rem;
        }
      }
    }
  }
}
