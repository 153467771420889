@import '../../../_mixin';
@import '../../../_variables';

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .layout__body {
    flex: 1;
  }
}
