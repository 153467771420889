.ride__card {
  padding: 1rem;
  background-color: #f7f7f7f7;
  border-radius: 1rem;
  box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .ride__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ride__date {
      display: block;
      width: 100%;
      text-align: start;
      font-weight: 800;
      font-size: 1.25rem;
      opacity: 0.6;
    }

    .ride__title {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .ride__arrow {
      font-weight: 800;
      font-size: 1.25rem;
    }
  }

  .ride__footer {
    .ride__driver {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .driver__name {
        font-size: 1.25rem;
        font-weight: 700;
      }

      .driver__email {
        font-size: 0.75rem;
      }

      .driver__phone {
        font-weight: 700;
        font-size: 0.75rem;
      }
    }
  }
}
