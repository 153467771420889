@import '../../_variables';
@import '../../_mixin';

.banner {
  padding: 1rem 0;
  background-color: $primary;

  .banner__container {
    @include container;

    .banner__text {
      font-size: 1.25rem;
      color: white;
    }
  }
}
